<template>
    <div class="event">

        <div v-if="!isLoading && !missingEvent" class="event-details">
            <h2>{{eventName}}</h2>
            <div>{{eventTime}}</div>
            <div class="event-capacity"><span>Capacity: {{capacity}}</span><span>Sold: {{sold}} ({{occupancyRate}}%)</span><span>Scanned: {{scanned}} ({{scanRate}}%)</span></div>
        </div>

        <div class="time-slot-list" v-if="!isLoading && isTimeSlotEvent">
            <div class="time-slot"
                 :class="{selected: isSelectedCapacityLocation(timeSlot.capacityLocationPath)}"
                 v-for="timeSlot in timeSlots"
                 :key="timeSlot.capacityLocationPath"
                 @click="onSelectCapacityLocation(timeSlot.capacityLocationPath)"
            >{{getTimeSlotName(timeSlot)}}</div>
        </div>

        <div v-if="!isLoading && scanMode && !missingEvent" class="scanner-wrapper">
            <te-scanner :capacity-location-path="capacityLocationPath"></te-scanner>
        </div>

        <div v-if="!isLoading && missingEvent" class="event-not-found">
            Event not found.
        </div>

<!--        <div v-if="!isLoading && !scanMode" class="scanner-wrapper">-->
<!--            sddssd-->
<!--        </div>-->

        <div v-if="isLoading" class="te-scanner-loading">
            <div class="te-loading-container">
                <div class="te-loading-container-row">
                    <div class="te-loading-spinner"><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="te-loading-container-row">
                    <span>{{loadingMessage}}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import moment from "moment";
    import Scanner from '@/components/Scanner';
    import * as jobTypes from "../store/job-types";

    export default {
        name: "Event",

        components: {
            'te-scanner': Scanner,
        },

        data() {
            return {
                scanMode: true,
                capacityLocationPath: null,
                now: Date.now(),
            };
        },

        computed: {
            ...mapGetters({
                error: 'error',
                jobs: 'jobs',
                event: 'event',
                access: 'access',
            }),

            isLoading: function() {
                return (
                    this.jobs.includes(jobTypes.GET_EVENT)
                );
            },

            isTimeSlotEvent: function () {
                if(this.event && this.event.hasTimeslots === true) {
                    return true;
                }
                return false;
            },

            timeSlots: function () {
                if(this.event && this.event.capacityLocationSummery) {
                    return this.event.capacityLocationSummery.filter((c) => {
                        if(c.open && c.close && moment(this.now).isBetween(c.open, c.close)) {
                            return true;
                        }
                        return c.open === null && c.close === null;
                    });
                }
                return [];
            },

            loadingMessage: function() {
                let message = this.languageSettings.loading;
                // if(this.jobs.includes(jobTypes.INIT_SCANNER)) message = this.languageSettings.initScanner;
                return message;
            },

            languageSettings: function() {
                const defaultSettings = {
                    loading: 'Loading...',
                    // initScanner: '',
                };
                return Object.assign(defaultSettings, this.language);
            },

            eventName: function() {
                return this.event ? this.event.name : '';
            },

            eventTime: function() {
                const time = this.event ? moment(this.event.start, 'YYYY-MM-DDTHH:mm').format('ddd Do MMM') : '';
                const location = this.event.location ? ' @ ' + this.event.location : '';
                return this.event ? time + location : '';
            },

            capacity: function() {
                return this.event.totalCapacity;
            },

            sold: function() {
                return this.event.grantedCapacity;
            },

            occupancyRate: function() {
                if(this.event.totalCapacity === 0) {
                    return '0';
                }
                return (this.event.grantedCapacity / (this.event.totalCapacity / 100)).toFixed(0);
            },

            scanned: function() {
                return this.event.usedCapacity;
            },

            scanRate: function() {
                if(this.event.grantedCapacity === 0) {
                    return '0';
                }
                return (this.event.usedCapacity / (this.event.grantedCapacity / 100)).toFixed(0);
            },

            missingEvent: function() {
                return this.event === null;
            },

        },

        methods: {
            ...mapActions([
                "useAccess",
                "getEvent",
                "checkEventUpdates",
                "setEventId",
            ]),

            getTimeSlotName(timeSlot) {
                if(timeSlot.start && timeSlot.end) {
                    return moment(timeSlot.start).format('HH:mm') + ' - ' + moment(timeSlot.end).format('HH:mm')
                }
                return timeSlot.name;
            },

            onSelectCapacityLocation(capacityLocationPath) {
                this.capacityLocationPath = capacityLocationPath;
            },

            initSelectedCapacityLocationPath() {
                if(this.isTimeSlotEvent && (this.capacityLocationPath === null || !this.timeSlots.map(c => c.capacityLocationPath).includes(this.capacityLocationPath))) {
                    this.capacityLocationPath = (this.timeSlots.length > 0) ? this.timeSlots[0].capacityLocationPath : null;
                }
            },

            isSelectedCapacityLocation(capacityLocationPath) {
                return this.capacityLocationPath === capacityLocationPath;
            },

            setNow() {
                this.now = Date.now();
                this.initSelectedCapacityLocationPath();
            },

        },

        watch: {
            event() {
                this.initSelectedCapacityLocationPath();
            },
        },

        created() {
            // get event
            const eventId = this.$route.params && this.$route.params.id ? this.$route.params.id : null;
            if(eventId) {
                this.setEventId(eventId);
                this.getEvent(eventId);
                this.initSelectedCapacityLocationPath();

                //
                const setNow = this.setNow.bind(this);
                setInterval(() => { this.checkEventUpdates(eventId) }, 1800000);
                setInterval(() => { setNow() }, 10000);
            }
        }

    }
</script>

<style scoped>

    .event {
        /*max-width: 600px;*/
        max-width: 100vw;
        min-height: 80vh;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }

    .event-details {
        text-align: center;
    }

    .event-capacity span {
        margin-left: 30px;
    }

    .event-capacity span:first-child {
        margin-left: 0;
    }

    .scanner-wrapper {
        flex-grow: 1;
    }

    .last-scan-result {
        /*flex-grow: 1;*/
    }

    .event-not-found {
        padding: 10px;
    }

    .time-slot-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .time-slot {
        margin: 5px;
        padding: 17px;
        border: 1px solid #939CA2;
        font-size: 1rem;
        line-height: 1rem;
        cursor: pointer;
    }

    .time-slot.selected {
        border-color: #e69d00;
        font-weight: bold;
    }

    h2 {
        font-size: 1.5rem;
        margin: 20px 0 10px 0;
    }

    div {
        margin-bottom: 10px;
    }

    .te-scanner-loading {
        flex-grow: 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .te-loading-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .te-loading-container-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 5px;
    }

    .te-loading-spinner {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
    }
    .te-loading-spinner div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 4px;
        border: 4px solid #e69d00;
        border-radius: 50%;
        animation: te-loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #e69d00 transparent transparent transparent;
    }
    .te-loading-spinner div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .te-loading-spinner div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .te-loading-spinner div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes te-loading-spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>
