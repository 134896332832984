<template>
    <div class="scanner">
        <qrcode-stream
                :track="false"
                @init="onInit"
                @decode="onDecode"
        >
        </qrcode-stream>

        <transition name="fade">
            <div v-if="isGrantedAccess && hasAccessResult && cameraInitialized" class="confirmation success__confirmation">
                <div class="confirmation--icon">
                    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="96px" height="96px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 16.292969 8.2929688 L 10 14.585938 L 7.7070312 12.292969 L 6.2929688 13.707031 L 10 17.414062 L 17.707031 9.7070312 L 16.292969 8.2929688 z"/></svg>
                </div>
                <div class="confirmation--message">
                    {{accessResultMessage}}
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="!isGrantedAccess && hasAccessResult && cameraInitialized" class="confirmation failure__confirmation">
                <div class="confirmation--icon">
                    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="96px" height="96px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z"/></svg>
                </div>
                <div class="confirmation--message">
                    {{accessResultMessage}}
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="isRequestingAccess && cameraInitialized" class="confirmation requesting__confirmation">
                <div class="confirmation--icon">
                    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="85px" height="85px"><g><g><g><path d="M196,40h120c11.046,0,20-8.954,20-20s-8.954-20-20-20H196c-11.046,0-20,8.954-20,20S184.954,40,196,40z"/><path d="M312.569,207.147l-70.71,70.71c-7.811,7.81-7.812,20.473-0.001,28.284c7.813,7.812,20.475,7.809,28.285,0l70.71-70.71,c7.811-7.81,7.812-20.473,0.001-28.284C333.043,199.337,320.381,199.338,312.569,207.147z"/><path d="M256,72C134.413,72,36,170.396,36,292c0,121.587,98.395,220,220,220c121.59,0,220-98.398,220-220,C476,170.413,377.604,72,256,72z M256,472c-99.252,0-180-80.748-180-180s80.748-180,180-180s180,80.748,180,180,S355.252,472,256,472z"/></g></g></g></svg>
                </div>
                <div class="confirmation--message">
                    {{languageSettings.requestingAccess}}
                </div>
            </div>
        </transition>

        <div v-if="!cameraInitialized" class="te-scanner-loading">
            <div class="te-loading-container">
                <div class="te-loading-container-row">
                    <div class="te-loading-spinner"><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="te-loading-container-row">
                    <span>{{languageSettings.initialize}}</span>
                </div>
            </div>
        </div>

        <div class="last-scan-result">
            <div v-if="previousResult === 'granted'">
                <svg fill="#2ed362" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40px" height="40px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 16.292969 8.2929688 L 10 14.585938 L 7.7070312 12.292969 L 6.2929688 13.707031 L 10 17.414062 L 17.707031 9.7070312 L 16.292969 8.2929688 z"/></svg>
            </div>
            <div v-if="previousResult === 'denied'">
                <svg fill="#ee5858" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40px" height="40px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z"/></svg>
            </div>
            <div v-if="previousResult === 'denied'">
                <div>{{previousMessage}}</div>
            </div>
        </div>

        <div v-if="cameraInitializationError !== null && cameraInitialized" class="camera-error">
            {{cameraInitializationError}}
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { QrcodeStream } from 'vue-qrcode-reader';
    import * as jobTypes from "../store/job-types";

    export default {
        name: "Scanner",

        components: {
            QrcodeStream,
        },

        props: {
            capacityLocationPath: {
                type: String
            },
        },

        data() {
            return {
                cameraInitialized: false,
                cameraInitializationError: null,
                // lastScanResult: null,
            };
        },

        computed: {
            ...mapGetters({
                error: 'error',
                jobs: 'jobs',
                event: 'event',
                access: 'access',
                previousAccess: 'previousAccess',
                hasAccessResult: 'hasAccessResult',
                isGrantedAccess: 'isGrantedAccess',
                isRequestingAccess: 'isRequestingAccess',
                accessResultMessage: 'accessResultMessage',
            }),

            scanInProgress: function() {
                return (
                    this.jobs.includes(jobTypes.USE_ACCESS)
                );
            },

            previousResult: function() {
                return this.previousAccess && this.previousAccess.status ? this.previousAccess.status : null;
            },

            previousMessage: function() {
                return this.previousAccess && this.previousAccess.message ? this.previousAccess.message : null;
            },

            languageSettings: function() {
                const defaultSettings = {
                    requestingAccess: 'Checking',
                    initialize: 'Initializing camera.',
                    notAllowedError: 'User denied camera access permisson.',
                    notFoundError: 'No suitable camera device installed.',
                    notSupportedError: 'Page is not served over HTTPS (or localhost).',
                    notReadableError: 'Maybe camera is already in use.',
                    overconstrainedError: 'Did you requested the front camera although there is none?',
                    streamApiNotSupportedError: 'Browser seems to be lacking features.',
                };
                return Object.assign(defaultSettings, this.language);
            },
        },

        methods: {
            ...mapActions([
                "useAccess",
            ]),

            async onInit (promise) {
                // show loading indicator
                try {
                    await promise

                    // successfully initialized
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        // user denied camera access permisson
                        this.cameraInitializationError = this.languageSettings.notAllowedError;
                    } else if (error.name === 'NotFoundError') {
                        // no suitable camera device installed
                        this.cameraInitializationError = this.languageSettings.notFoundError;
                    } else if (error.name === 'NotSupportedError') {
                        // page is not served over HTTPS (or localhost)
                        this.cameraInitializationError = this.languageSettings.notSupportedError;
                    } else if (error.name === 'NotReadableError') {
                        // maybe camera is already in use
                        this.cameraInitializationError = this.languageSettings.notReadableError;
                    } else if (error.name === 'OverconstrainedError') {
                        // did you requested the front camera although there is none?
                        this.cameraInitializationError = this.languageSettings.overconstrainedError;
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        // browser seems to be lacking features
                        this.cameraInitializationError = this.languageSettings.streamApiNotSupportedError;
                    }
                } finally {
                    // hide loading indicator
                    this.cameraInitialized = true;
                }
            },

            repaint (location, ctx) {
                const {
                    topLeftCorner,
                    topRightCorner,
                    bottomLeftCorner,
                    bottomRightCorner,
                    // topLeftFinderPattern,
                    // topRightFinderPattern,
                    // bottomLeftFinderPattern
                } = location;

                ctx.strokeStyle = '#ffae00'; // instead of red

                ctx.beginPath();
                ctx.moveTo(topLeftCorner.x, topLeftCorner.y);
                ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y);
                ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y);
                ctx.lineTo(topRightCorner.x, topRightCorner.y);
                ctx.lineTo(topLeftCorner.x, topLeftCorner.y);
                ctx.closePath();

                ctx.stroke()
            },

            onDecode (decodedString) {
                this.useAccess({token: decodedString, eventId: this.event.id, eventManagerId: this.event.eventManagerId, capacityLocationPath: this.capacityLocationPath});
                // this.onSuccess('Some success message');
            },
        },

        // watch: {
        //     access(newValue) {
        //         if(newValue && newValue.status) {
        //             this.lastScanResult = newValue.status;
        //         }
        //     },
        // },

    }
</script>

<style scoped lang="scss">

    .scanner {
        display: block;
        position: relative;
        /*border-radius: 25px;*/
    }

    .wrapper {
        z-index: 1;
    }

    .confirmation {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*opacity: 0;*/
        /*transition: opacity 1s linear;*/
    }

    .confirmation--icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .confirmation--message {
        margin-top: 8px;
        padding: 0 30px;
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .success__confirmation {
        background-color: #2ed362;
    }

    .failure__confirmation {
        background-color: #ee5858;
    }

    .requesting__confirmation {
        background-color: #ffae00;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .05s ease-in;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .last-scan-result {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 10px;
            text-align: center;
            word-break: break-all;
        }
    }

    .camera-error {
        padding: 30px;
        background-color: #ee5858;
        text-align: center;
        color: #fff;
    }


    .te-scanner-loading {
        flex-grow: 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .te-loading-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .te-loading-container-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 5px;
    }

    .te-loading-spinner {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
    }
    .te-loading-spinner div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 4px;
        border: 4px solid #e69d00;
        border-radius: 50%;
        animation: te-loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #e69d00 transparent transparent transparent;
    }
    .te-loading-spinner div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .te-loading-spinner div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .te-loading-spinner div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes te-loading-spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }


</style>
